@import url(https://unpkg.com/@tailwindcss/typography@0.3.x/dist/typography.min.css);
@import url(https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css);
@import url(https://fonts.googleapis.com/css2?family=Castoro:ital@1&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Frijole&family=Montserrat:wght@500;600;700&family=Pattaya&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@500;700;900&display=swap);
.headerFont {
  font-family: "Castoro";
}

.tabFont {
  font-family: "Montserrat";
}

.bodyFont {
  font-family: "Noto Sans KR";
}

.homeLabelChildPadding {
  padding-bottom: 44.5920304%;
}

.imageRed {
  color: rgb(207,75,70);
}

.offWhite {
  color: rgb(234,218,181);
}

main {
  min-height: 100vh;
  min-width: 100-vw;
  background-size: cover;
}


.bgImageOffWhite {
  background-color: rgb(234,218,181);
}


@media screen and (max-width: 639px){
  .textDiv {
    top: 9%;
    left: 48%;
    padding-right: 2rem;
    padding-left: 0.5rem;
    padding-top: 2.25rem;
    font-size: 1.375rem;
    line-height: 1.25rem;
  }

  .innerTextDiv{
    margin-top: 3rem;
    margin-left: 0.5rem;
  }

  .secondTextDiv{
    margin-top: 1rem;
  }

  .rightText {
    margin-left:0.375rem;
  }
}

@media screen and (max-width: 539px){
  .nameTab {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-left: 1.125rem;
    margin-right: 0.5rem;
    margin-top: 0.6rem;
    letter-spacing: -0.025em;
  }

  .tabsDiv {
    font-size: 1rem;
    margin-left: 0.5rem;
    margin-top: 0.25rem;
    line-height: 1rem;
  }

  .activeNav {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
    padding-top: 0.125rem;
  }

  .contentDiv {
    padding-top: 7rem;
    padding-bottom: 31.5rem;
  }

  /* .textDiv {
    top: 13%;
    left: 49%;
    font-size: 1.125rem;
    line-height: 1.25rem;
  } */

  .textDiv {
    top: 6.5%;
    left: 48%;
    padding-right: 0.4rem;
    padding-left: 0.2rem;
    padding-top: 2rem;
    font-size: 1rem;
    line-height: 1.25rem;
  }

  .innerTextDiv{
    margin-top: 2rem;
    margin-left: 0.5rem;
  }

  .secondTextDiv{
    margin-top: 0.5rem;
  }

  .rightText {
    margin-left:0.375rem;
  }

  .projHeader {
    margin-left: 5rem;
    margin-right: 5rem;
    margin-bottom: 2.5rem;
  }
}

@media screen and (max-width: 539px) and (max-height: 822px){
  .nameTab {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-left: 1.125rem;
    margin-right: 0.5rem;
    margin-top: 0.6rem;
    letter-spacing: -0.025em;
  }

  .tabsDiv {
    font-size: 1rem;
    margin-left: 0.5rem;
    margin-top: 0.25rem;
    line-height: 1rem;
  }

  .activeNav {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
    padding-top: 0.125rem;
  }

  .contentDiv {
    padding-top: 7rem;
    padding-bottom: 31.5rem;
  }

  /* .textDiv {
    top: 15%;
    left: 49%;
    font-size: 1.125rem;
    line-height: 1.25rem;
  } */

  .textDiv {
    top: 6%;
    left: 48%;
    padding-right: 0.4rem;
    padding-left: 0.2rem;
    padding-top: 2.5rem;
    font-size: 1rem;
    line-height: 1.25rem;
  }

  .innerTextDiv{
    margin-top: 2.25rem;
    margin-left: 0.5rem;
  }

  .secondTextDiv{
    margin-top: 0.5rem;
  }

  .rightText {
    margin-left:0.375rem;
  }

}

@media screen and (max-width: 410px) {
  .nameTab {
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin-left: 1.5rem;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
    letter-spacing: -0.025em;
  }

  .tabsDiv {
    font-size: 0.875rem;
    margin-left: 0.5rem;
    margin-top: 0.125rem;
    line-height: 1rem;
  }

  .activeNav {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
    padding-top: 0.125rem;
  }

  .contentDiv {
    padding-top: 6rem;
    padding-bottom: 31.5rem;
  }

    .textDiv {
    top: 4%;
    left: 48%;
    padding-right: 0.4rem;
    padding-left: 0.2rem;
    padding-top: 2.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .innerTextDiv{
    margin-top: 2rem;
    margin-left: 0.5rem;
  }

  .secondTextDiv{
    margin-top: 0.5rem;
  }

  .rightText {
    margin-left:0.375rem;
  }

  .projHeader {
    margin-left: 4.5rem;
    margin-right: 4.5rem;
    margin-bottom: 2rem;
  }

}

@media screen and (max-width: 410px) and (max-height: 811px) {
  .nameTab {
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin-left: 1.5rem;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
    letter-spacing: -0.025em;
  }

  .tabsDiv {
    font-size: 0.875rem;
    margin-left: 0.5rem;
    margin-top: 0.125rem;
    line-height: 1rem;
  }

  .activeNav {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
    padding-top: 0.125rem;
  }

  .contentDiv {
    padding-top: 6rem;
    padding-bottom: 31.5rem;
  }

  /* .textDiv {
    top: 12%;
    left: 49%;
    font-size: 1rem;
    line-height: 1.25rem;
  } */

  .textDiv {
    top: 4%;
    left: 48%;
    padding-right: 0.4rem;
    padding-left: 0.2rem;
    padding-top: 3rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .innerTextDiv{
    margin-top: 2.125rem;
    margin-left: 0.5rem;
  }

  .secondTextDiv{
    margin-top: 0.5rem;
  }

  .rightText {
    margin-left:0.375rem;
  }

  .projSection {
    margin-left: 1.75rem;
    margin-right: 2rem;
  }


}

@media screen and (max-width: 374px){
  .nameTab {
    font-size: 1.125rem;
    line-height: 1.75rem;
    margin-left: 0.75rem;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
    letter-spacing: -0.025em;
  }

  .tabsDiv {
    font-size: 0.75rem;
    margin-left: 0.75rem;
    line-height: 1.25rem;
  }

  .activeNav {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .contentDiv {
    padding-top: 4rem;
    padding-bottom: 23rem;
  }


  .textDiv {
    top: 5%;
    left: 48%;
    padding-right: 0.4rem;
    padding-left: 0.2rem;
    padding-top: 3rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .innerTextDiv{
    margin-top: 1.875rem;
    margin-left: 0.5rem;
  }

  .secondTextDiv{
    margin-top: 0.25rem;
  }

  .rightText {
    margin-left:0.375rem;
  }

  .projHeader{
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    margin-left: 3rem;
    margin-right: 3rem;
    margin-bottom: 2rem;
  }

  .projArticle {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .projSection {
    margin-left: 1.75rem;
  }

  .techSpan {
    font-size: 1rem;
    line-height: 1.75rem;
  }

  .techHeader {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .projHeader {
    margin-left: 3rem;
    margin-right: 3rem;
  }


}


@media screen and (max-width: 359px){
  .nameTab {
    font-size: 1.125rem;
    line-height: 1.75rem;
    margin-left: 0.75rem;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
    letter-spacing: -0.025em;
  }

  .tabsDiv {
    font-size: 0.75rem;
    margin-left: 0.75rem;
    line-height: 1.25rem;
  }

  .activeNav {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .contentDiv {
    padding-top: 4rem;
    padding-bottom: 19.5rem;
  }

  .textDiv {
    top: 4.5%;
    left: 48%;
    padding-right: 0.4rem;
    padding-left: 0.2rem;
    padding-top: 3rem;
    font-size: 0.75rem;
    line-height: 1.25rem;
  }

  .innerTextDiv{
    margin-top: 1.75rem;
    margin-left: 0.5rem;
  }

  .secondTextDiv{
    margin-top: 0.25rem;
  }

  .rightText {
    margin-left:0.375rem;
  }

  .projHeader{
    font-size: 1rem;
    line-height: 1.5rem;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    margin-left: 3rem;
    margin-right: 3rem;
    margin-bottom: 2rem;
  }

  .projArticle {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .projSection {
    margin-left: 1.75rem;
  }

  .techSpan {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .techIcon {
    width: 2rem;
    height:2rem;
  }

  .fiSpan {
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: -0.025em;
  }

  .apiSpan {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .descriptionP {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  
}

  @media screen and (max-width: 319px){
    .nameTab {
      font-size: 0.875rem;
      line-height: 1.25rem;
      margin-left: 0.5rem;
      margin-right: 0.25rem;
      margin-top: 0.75rem;
      letter-spacing: -0.025em;
    }
  
    .tabsDiv {
      font-size: 0.75rem;
      margin-left: 0.125rem;
      margin-top: 0.125rem;
      line-height: 1.25rem;
    }

    .activeNav {
      padding-left: 0.25rem;
      padding-right: 0.25rem;
    }

    .contentDiv {
      padding-top: 3.5rem;
      padding-bottom: 27rem;
    }

    .textDiv {
      top: 3.5%;
      left: 48%;
      padding-right: 0.4rem;
      padding-top: 3rem;
      font-size: 0.675rem;
      line-height: 1.25rem;
    }

    .innerTextDiv{
      margin-top: 0.75rem;
    }
    
    .topTextDiv {
      margin-top: 0.1rem;
    }

    .rightText {
      margin-left:0.375rem;
    }

  }

.App {
  text-align: center;
  background-size: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

